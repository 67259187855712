<template>
  <div>
    <c-table
      ref="table"
      title="훈련시나리오 개정 목록"
      tableId="revTable"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'traning-scenario-renew-pop',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        groupId: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'traningScnario',
            field: 'traningScnario',
            label: '훈련시나리오',
            align: 'center',
            child: [
              {
                name: 'revisionNum',
                field: 'revisionNum',
                label: 'Rev.',
                align: 'center',
                sortable: true,
                style: 'width:80px',
              },
              {
                name: 'trainingScenarioName',
                field: 'trainingScenarioName',
                label: '훈련시나리오명',
                align: 'left',
                sortable: true,
                style: 'width:150px',
              },
              {
                name: 'trainingContentsSummary',
                field: 'trainingContentsSummary',
                label: '훈련내용요약',
                align: 'left',
                sortable: true,
                style: 'width:200px',
              },
              {
                name: 'revisionContent',
                field: 'revisionContent',
                label: 'LBLREVREASON',
                align: 'left',
                sortable: true,
                style: 'width:200px',
              },
              {
                name: 'regDtStr',
                field: 'regDtStr',
                label: 'LBLREVPERIOD',
                style: 'width: 150px',
                align: 'center',
                sortable: true,
              },
              {
                name: 'regUserName',
                field: 'regUserName',
                label: 'LBLREVUSER',
                align: 'center',
                style: 'width: 80px',
                sortable: true,
              },
            ]
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        col1: null,
        useFlag: 'Y',
        keyword: '',
      },
      searchUrl: '',
      editable: true,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      revlistUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.revlistUrl = selectConfig.sop.eap.scenario.rev.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: this.popupParam.groupId};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      // 비상조치계획서 상세 팝업
      this.popupOptions.title = '비상조치계획서 상세'; // 비상조치계획서 상세
      this.popupOptions.param = {
        isSearch: true,
      };
      this.popupOptions.target = () => import(`${'./emergencyActionPlanDocuDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
