var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        ref: "table",
        attrs: {
          title: "훈련시나리오 개정 목록",
          tableId: "revTable",
          columns: _vm.grid.columns,
          data: _vm.grid.data,
        },
        on: { linkClick: _vm.linkClick },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }